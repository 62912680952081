var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"userpage"},[_c('v-row',[_c('v-col',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],attrs:{"cols":"12","sm":"3"}},[_c('v-toolbar',{attrs:{"flat":"","dense":""}},[_c('v-toolbar-title',[_vm._v("My Health Blogs")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":_vm.refresh}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],1),_c('v-divider'),_c('v-list',{staticStyle:{"padding":"0"},attrs:{"dense":""}},[_c('v-list-item-group',{staticStyle:{"padding":"0"},attrs:{"color":"success"}},[_c('v-list-item',{attrs:{"to":{ name: 'account.blog.new' }}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("New health blog")])],1),_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-post-outline")])],1)],1)],1)],1),_c('v-divider'),_c('div',{staticClass:"blogist scrollable",style:({ height: _vm.panheight })},[_c('ul',_vm._l((_vm.blogList),function(a){return _c('li',{key:a.id},[_c('router-link',{attrs:{"to":{
                name: 'account.blog.page',
                params: {
                  id: _vm._encode(a.id),
                  title: _vm._encode(a.title),
                },
              }}},[_c('div',{staticClass:"mytitles"},[_c('div',[_vm._v(_vm._s(a.title))]),_c('v-icon',{attrs:{"color":a.publish ? 'blue' : 'red'}},[_vm._v(" "+_vm._s(a.publish ? "mdi-check" : "mdi-close")+" ")])],1)])],1)}),0)])],1),_c('v-col',{attrs:{"cols":"12","sm":"9"}},[_c('router-view')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }