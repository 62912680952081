<template>
  <div class="userpage">
    <v-row>
      <v-col cols="12" sm="3" v-resize="onResize">
        <v-toolbar flat dense>
          <v-toolbar-title>My Health Blogs</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon color="primary" @click="refresh">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>

        <v-list dense style="padding: 0">
          <v-list-item-group color="success" style="padding: 0">
            <v-list-item :to="{ name: 'account.blog.new' }">
              <v-list-item-content>
                <v-list-item-title>New health blog</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon>mdi-post-outline</v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <v-divider></v-divider>
        <div class="blogist scrollable" :style="{ height: panheight }">
          <ul>
            <li v-for="a in blogList" :key="a.id">
              <router-link
                :to="{
                  name: 'account.blog.page',
                  params: {
                    id: _encode(a.id),
                    title: _encode(a.title),
                  },
                }"
              >
                <div class="mytitles">
                  <div>{{ a.title }}</div>
                  <v-icon :color="a.publish ? 'blue' : 'red'">
                    {{ a.publish ? "mdi-check" : "mdi-close" }}
                  </v-icon>
                </div>
              </router-link>
            </li>
          </ul>
        </div>
      </v-col>
      <v-col cols="12" sm="9">
        <router-view></router-view>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Restful from "@/services/RestFul";
import Utils from "../../../mixins/utils.js";
import { VSpacer } from "vuetify/lib";
export default {
  components: { VSpacer },
  mixins: [Utils],
  data: () => ({
    blogList: [],
  }),
  computed: {
    panheight: function () {
      let w = this.windowSize.x;
      console.log("w", w);
      if (w > 450) {
        let h = this.windowSize.y - 200 + "px";
        return h;
      } else {
        return "120px";
      }
    },
  },
  created() {
    this.mylist();
  },
  methods: {
    mylist() {
      this.loading = true;
      let self = this;
      Restful.blogs
        .userlist()
        .then((response) => {
          console.log(response.data);
          this.blogList = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
          self.loading = false;
        });
    },
    refresh() {
      this.mylist();
    },
  },
};
</script>
